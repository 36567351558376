@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: '/assets/fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: '/' !default;
$slick-arrow-color: white !default;
$slick-dot-color: #ddd !default;
$slick-dot-color-active: #c69c6d !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url('/assets/images/ajax-loader.gif') center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == 'slick' {
	@font-face {
		font-family: 'slick';
		src: url('/assets/fonts/slick.eot');
		src: url('/assets/fonts/slick.eot?#iefix') format('embedded-opentype'),
			 url('/assets/fonts/slick.woff') format('woff'),
			 url('/assets/fonts/slick.ttf') format('truetype'),
			 url('/assets/fonts/slick.svg#slick') format('svg');
		font-weight: normal;
		font-style: normal;
	  }
	  
	// @font-face {
	// 	font-family: 'slick';
	// 	src: slick-font-url('slick.eot');
	// 	src: slick-font-url('slick.eot?#iefix') format('embedded-opentype'), slick-font-url('slick.woff') format('woff'),
	// 		slick-font-url('slick.ttf') format('truetype'), slick-font-url('slick.svg#slick') format('slick.svg');
	// 	font-weight: normal;
	// 	font-style: normal;
	// }
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover,
	&:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		font-family: $slick-font-family;
		font-size: 20px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	left: -25px;
	[dir='rtl'] & {
		left: auto;
		right: -25px;
	}
	&:before {
		content: $slick-prev-character;
		[dir='rtl'] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: -25px;
	[dir='rtl'] & {
		left: -25px;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir='rtl'] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -45px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 2px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 12px;
			width: 12px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;

			&:hover,
			&:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
					border: 2px solid $slick-dot-color-active;
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 16px;
				height: 16px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 16px;
				text-align: center;
				// color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				border: 2px solid #ddd;
				border-radius: 50%;
			}
		}
		&.slick-active button:before {
			// color: $slick-dot-color-active;
			opacity: $slick-opacity-default;

			border-radius: 50%;
			border: 2px solid $slick-dot-color-active;
			background-color: transparent;
		}
	}
}
