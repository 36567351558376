.seller-account-form-wrapper {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.seller-account-form {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .seller-account-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .seller-account-form-wrapper {
    width: 100%;
  }
}
