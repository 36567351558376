@use "bootstrap-scss/bootstrap";

@use "./landing_page.scss";
@use "../scss/animate";
// @use "react-toastify/dist/ReactToastify.css";
@use "font-awesome";

@use "theme/variables"  as vars;
@use "theme/manage_style";
@use "theme/style";
@use "theme/main";
@use "theme/menu";
@use "theme/responsive";

@use "./slick";
@use "./slick-theme";
@use "./messenger";

:root {
  // --theme-deafult: #20963d;
  // This gradient color only for gym layout
  --theme-gradient1: #01effc;
  --theme-gradient2: #485ff2;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-input:focus {
  border-color: #ccc !important;
  box-shadow: none;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: unset !important;
}

.slick-arrow.slick-prev {
  //margin-left: 10px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:before {
    color: transparent;
  }
}

.slick-arrow.slick-next {
  // margin-right: 10px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:before {
    color: transparent;
  }
}

.default-outlined-btn {
  &:hover,
  &:active {
    border-color: #6c757d;
    color: #6c757d !important;
    background-color: #fff;
  }
}

.btn.btn-outline-danger {
  color: #dc3545 !important;
  &:hover,
  &:active {
    color: #fff !important;
  }
}

.btn.btn-secondary {
  padding: 6px 10px;
  background-color: #021a49;
  border-color: #021a49;
  &:active {
    background-color: #021a49 !important;
    border-color: #021a49 !important;
  }
}

.dashboard-title {
  font-size: 22px !important;
}

@media (max-width: 767px) {
  .card .card-header div.pull-right {
    margin-top: 1.5rem;
  }
}

.card.StripeElement {
  width: 100%;
  margin-bottom: 0;
}

::-webkit-file-upload-button {
  background: #021a49;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.purchase-file-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  max-width: 100%;
  padding: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.go-back {
  border-radius: 10px;
  background-color: transparent;
  position: absolute;
  left: 10px;
  cursor: pointer;
  z-index: 9;
  top: 10px;
  line-height: 20px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  border: solid 1px;
  padding: 0.4rem 1rem;
}

.map-search {
  .modal-content {
    border: 2px solid #20963d;
    padding: 5px 10px;
    .modal-header {
      border: none;
      padding: 0;
    }
  }
}

.map-popup {
  .mapboxgl-popup-content {
    padding: 20px;
    font-size: 14px;
    font-weight: 700;

    a {
      cursor: pointer;
    }
  }
}

.link {
  color: #20963d;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

#rc-imageselect {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

button.disabled {
  cursor: initial;
  &:hover {
    background: #20963d !important;
    color: #fff !important;
  }
}

@media (max-width: 767px) {
  .seller-produces {
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 5px;
    }
  }

  .home-slider {
    .home {
      height: 38vh !important;
    }

    .slider-contain {
      height: 35vh !important;
      h4 {
        margin-bottom: 0 !important;
      }
    }
  }

  .favourite-banner-panel {
    // height: 41vh !important;
    height: 41vh;
    img {
      width: 100px;
      height: 100px;
    }
    .favourite-banner-info {
      display: flex;
      left: 15px;
      bottom: -55px;
      padding: 5px 0;
      // background-color: #0000006e;

      .col-md-8 {
        width: 154px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .col-md-4 {
        width: calc(100% - 154px) !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          margin: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .favourite-banner-panel {
    .favourite-banner-info {
      bottom: 5px;
    }
  }
}

@media (max-width: 481px) {
  .favourite-banner-panel {
    .col-md-8 {
      scale: 0.8;
    }
    .col-md-4 {
      scale: 0.8;
    }
  }
}

@media (max-width: 767px) {
  .favourite-banner-panel {
    height: 46vh;
  }
}
//react-select
.css-1jqq78o-placeholder {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
// reactstrap-tooltip
.tooltip-inner {
  position: relative;
}

.tooltip-inner::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #021a49 transparent transparent transparent;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip-arrow {
  display: none !important;
}

.fs-18 {
  font-size: 18px;
}
.fw-bold {
  font-weight: bold;
}
.error-color {
  color: #ff0000;
}
